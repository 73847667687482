import React from 'react';

// import './NadpisPacketa.css';

const NadpisPacketa = () => (
    <h2 className='heading'>
        <span className='dark'>Zásilky z Pohody do Packeta/Zásilkovny API</span>
    </h2>
);

export default NadpisPacketa;
