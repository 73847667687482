import {AppBar, Toolbar} from "@mui/material";
import React from "react";
// import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import {apiKeys} from "./apiKeys";
// import App from "./App";

//
// function ElevationScroll(props) {
//     const { children, window } = props;
//     // Note that you normally won't need to set the window ref as useScrollTrigger
//     // will default to window.
//     // This is only being set here because the demo is in an iframe.
//     const trigger = useScrollTrigger({
//         disableHysteresis: true,
//         threshold: 0,
//         target: window ? window() : undefined,
//     });
//
//     return React.cloneElement(children, {
//         elevation: trigger ? 4 : 0,
//     });
// }
//
// ElevationScroll.propTypes = {
//     children: PropTypes.element.isRequired,
//     /**
//      * Injected by the documentation to work in an iframe.
//      * You won't need it on your project.
//      */
//     window: PropTypes.func,
// };

export default function ElevateAppBar(props) {
    return (
// <ElevationScroll>
    <AppBar
        position="sticky"
        color="default"
        elevation={0}
        sx={{
            py:0.3,
            backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.grey[200]
                    : theme.palette.grey[800],

            // borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            opacity: 0.2,
        }}
    >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
            <Typography variant="h4" color="black" noWrap sx={{ flexGrow: 0.5 }}>
                {apiKeys.appTitle}
            </Typography>
            <Typography variant="h5" color="black" noWrap sx={{ flexGrow: 0.5 }}>
                {apiKeys.nadpis}
            </Typography>

            <nav>
                {/*<Link*/}
                {/*    variant="button"*/}
                {/*    variant="button"*/}
                {/*    color="text.primary"*/}
                {/*    href="#"*/}
                {/*    sx={{ my: 1, mx: 1.5 }}*/}
                {/*>*/}
                {/*    Features*/}
                {/*</Link>*/}
                {/*<Link*/}
                {/*    variant="button"*/}
                {/*    color="text.primary"*/}
                {/*    href="#"*/}
                {/*    sx={{ my: 1, mx: 1.5 }}*/}
                {/*>*/}
                {/*    Enterprise*/}
                {/*</Link>*/}
                {/*<Link*/}
                {/*    variant="button"*/}
                {/*    color="text.primary"*/}
                {/*    href="#"*/}
                {/*    sx={{ my: 1, mx: 1.5 }}*/}
                {/*>*/}
                {/*    Support*/}
                {/*</Link>*/}
            </nav>
            {/*<Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }}>*/}
            {/*    Login*/}
            {/*</Button>*/}
        </Toolbar>
    </AppBar>
// </ElevationScroll>
    );
}
