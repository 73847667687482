import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

// import Zapati from "./Zapati";
import 'bootstrap/dist/css/bootstrap.min.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FooterSticky from "./FooterSticky";
import CssBaseline from "@mui/material/CssBaseline";
import {GlobalStyles} from "@mui/material";
import ElevateAppBar from "./AppBarSticky";
import App from "./App";

const defaultTheme = createTheme();

ReactDOM.render(
    <React.Fragment>
        <ThemeProvider theme={defaultTheme}>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <ElevateAppBar/>

            <App/>


        <FooterSticky/>
            {/*<Zapati/>*/}


        </ThemeProvider>
    </React.Fragment>,
    document.getElementById('root')
);
