import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import {apiKeys} from "./apiKeys";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
        version(appFe) {apiKeys.version} {' '}
      {'Copyright © '}
      <Link color="inherit" href="https://sevasoft.eu/">
        SEVASOFT Atelier
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function FooterSticky() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
        {/*<Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">*/}
        {/*  <Typography variant="h2" component="h1" gutterBottom>*/}
        {/*    Sticky footer*/}
        {/*  </Typography>*/}
        {/*  <Typography variant="h5" component="h2" gutterBottom>*/}
        {/*    {'Pin a footer to the bottom of the viewport.'}*/}
        {/*    {'The footer will move as the main element of the page grows.'}*/}
        {/*  </Typography>*/}
        {/*  <Typography variant="body1">Sticky footer placeholder.</Typography>*/}
        {/*</Container>*/}
        <Box
          component="footer"
          sx={{
            py: .5,
            px: 1,
            mt: 'auto',
            opacity: 0.3,
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          }}
        >
          <Container maxWidth="sm">
            <Typography variant="body2">
              Pomůcka pro podání zásilek do datového rozhraní dopravců.
            </Typography>
            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
