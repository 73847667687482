export const apiKeys = {
  version: "1.3.4.20240208",

  // base: 'http://packeta.topkosmetika.pohoda.dumaservis.cz:8082',
  // appTarget: "packeta",
  // nadpis: "Zásilky z Pohody do Packeta/Zásilkovny API",

//  base: 'http://192.168.113.108:8084',

  // base: 'http://ppl.ime.cz.pohoda.dumaservis.cz:8084',
//  appTarget: "pplimecz",
//  nadpis: "Zásilky IME.CZ z Pohody do PPL CZ API",
//  appFavicon: "ppl",
//  appTitle: "Ime.Cz",

   base: 'http://packeta.ime.cz.pohoda.dumaservis.cz:8085',
   appTarget: "packetaimecz",
   nadpis: "Zásilky IME.CZ z Pohody do Packeta/Zásilkovna API",
   appFavicon: "packeta",
   appTitle: "Ime.Cz",

  // base: 'http://ppl.topkosmetika.pohoda.dumaservis.cz:8083',
  // appTarget: "ppl",
  // nadpis: "Zásilky z Pohody do PPL CZ API",
  //


  // Sevasoft Střížkov
  // base: 'http://192.168.111.108:8081',
  // base: 'http://192.168.201.108:8081',
  // txtButton: "Načti zásilky Zásilkovna z Pohody",
  // txtNadpisPPL: "Načti zásilky PPL z Pohody",
  // txtNadpis: "Načti zásilky Zásilkovna z Pohody",
  // txtNadpisPPL: "Načti zásilky PPL z Pohody",
  // base: 'http://192.168.113.108:8081',


};
// key: 'e902985907738b357b6a7c7a2651a108',
