import React, {useEffect} from 'react';
import Favicon from "react-favicon";
// import React from 'react';
import NadpisPacketa from "./Components/Nadpis/NadpisPacketa";
import ZasilkyPacketaList from "./Components/ZasilkyList/ZasilkyPacketaList";
import {apiKeys} from "./apiKeys";
import ZasilkyPPLList from "./Components/ZasilkyList/ZasilkyPPLList";
import NadpisPPL from "./Components/Nadpis/NadpisPPL";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

function App() {
    const faviconUrl = "favicon.ico";
    const faviconPPLUrl = "faviconPPL.ico";

useEffect(() => {
        if (apiKeys.appTarget === "ppl") {
            document.title = 'PPL TopKo Zásilky';
        }
        else if (apiKeys.appTarget === "packeta") {
            document.title = 'Packeta TopKo Zásilky';
        }
        else if (apiKeys.appTarget === "pplimecz") {
            document.title = 'PPL Ime.cz Zásilky';
        }
        else if (apiKeys.appTarget === "packetaimecz") {
            document.title = 'Packeta Ime.cz Zásilky';
        }
    }, []);

    if (apiKeys.appFavicon ===  "ppl") {
        return (
            // <Container>
                <Box sx={{ my: 2 }}>
            <div className='App'>
                <Favicon url={faviconPPLUrl} />
                {/*<NadpisPPL/>*/}
                <ZasilkyPPLList></ZasilkyPPLList>
                <div className="monitoring">Monitoring dkwkdlg45</div>
            </div>
                </Box>
            // </Container>
        )
    }
    else {
        return (
            // <Container>
            <div className='App'>
                <Favicon url={faviconUrl} />
                {/*<NadpisPacketa/>*/}
                <ZasilkyPacketaList></ZasilkyPacketaList>
                <div className="monitoring">Monitoring dkwkdlg45</div>
            </div>
            // </Container>
        )

    }



}


export default App;
